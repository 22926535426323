import React, { useEffect, useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import logo from './images/CW_Logo-retina.png';
import axios from 'axios';


// Local Pagth 

function Login(props) {
    const [employeeID, setEmployeeID] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // initial state set here     
    const [programYear, setProgramYear] = useState(2024);
    const navigate = useNavigate();
    
    const [loginNote, setLoginNote] = useState("");
    useEffect(() => {
        const note = localStorage.getItem('loginNote');
        if (note) {
            setLoginNote(note);
            localStorage.removeItem('loginNote');
        }
    }, []);
   
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://cw-eip.azurewebsites.net/Employee/employeeLogin', {
                EmployeeID: employeeID,
                Password: password
            });
            
            const employeeData = response.data;
             
            setProgramYear(employeeData.programYear);
            setLoginNote(''); 
 
            localStorage.setItem('auth_token', employeeData.token);

            // Save the JSON string to local storage
            localStorage.setItem('userDataEIP', employeeData);
            localStorage.setItem('isAdmin', String(employeeData.isAdmin));
           
            localStorage.setItem('programYear', String(programYear));
            localStorage.setItem('currentEmployeeID', String(employeeID));
            localStorage.setItem('isManager', String(employeeData.isManager));
            localStorage.setItem('firstName', String(employeeData.firstName));
            localStorage.setItem('lastName', String(employeeData.lastName));
            localStorage.setItem('allowPrintAll', String(employeeData.allowPrintAll));
 
            navigate(`/EmployeeSelection/${programYear}/${employeeID}`);       
            
        } catch (error) {
            console.error("Error logging in:", error);
            if (error.response) {
                console.error("Server Response:", error.response.data);
            }
            // Here's the new line to set the error message
            setErrorMessage("Invalid Employee ID or password");
        }       
    };

    return (
        <div className="login-container">         
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo} alt="Company Logo" style={{ maxHeight: 160, marginRight: '10px' }} />
                <h1>{programYear} Swag Selection Login</h1>
            </div>
            {loginNote && <p className="login-note">{loginNote}</p>} {/* Display the login note here */}
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label htmlFor="employeeID">Employee ID:</label>
                    <input
                        type="text"
                        id="employeeID"
                        value={employeeID}
                        autoComplete='username'
                        onChange={(e) => setEmployeeID(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        autoComplete='current-password'
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
 