import React, { useEffect, useState } from 'react';
import logo from './images/2024/CW_Logo-retina.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './App.css';
import { FaEdit, FaPrint } from 'react-icons/fa';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import useAuthCheck from './useAuthCheck';

function ManagersView() {
    const [managerName, setManagerName] = useState('');
    const [managerEmployees, setManagerEmployees] = useState([]);
    const { programYear, managersEmployeeID } = useParams();
    const [backendUrl, setBackendUrl] = useState('https://cw-eip.azurewebsites.net');
    const navigate = useNavigate();
    const location = useLocation();
    const allowPrintAll = localStorage.getItem('allowPrintAll');
    const allowPrintAllBool = allowPrintAll === 'true';
    const [printAllClicked, setPrintAllClicked] = useState(false);
    const [isManager, setIsManager] = useState(true);
    const isFromManagerView = location.pathname.includes('/ManagersView/');
    const isEmployeeSelectionPage = location.pathname.includes('/EmployeeSelection/');
    useAuthCheck(managersEmployeeID); // Check the auth state
    // Fetch the manager's employees when the component mounts3
    useEffect(() => {
      
        const isMgr = localStorage.getItem('isManager');  
        if (isMgr == 'true') {
            const managerFirstName = localStorage.getItem('firstName');
            const managerLastName = localStorage.getItem('lastName');
            setManagerName(managerFirstName + ' ' + managerLastName);
        }
    
        fetchManagerEmployeesData();
 
    }, []);

    // Function to fetch the manager's employees data
    async function fetchManagerEmployeesData() {
        try {
            
            const response = await axios.get(`${backendUrl}/Employee/${managersEmployeeID}/manager`);
            setManagerEmployees(response.data);

            const jsonData = JSON.stringify(response.data);
            // Save the JSON string to local storage
            localStorage.setItem('managersEmployees', jsonData);


        } catch (error) {
            console.error('Error fetching manager employees:', error);
        }
    }
    // Helper function to format the hireDate as a date string
    function formatDate(hireDate) {
        const date = new Date(hireDate);
        return date.toLocaleDateString();
    }
    function loadEmployee(item) {
        
        navigate(`/EmployeeSelection/${programYear}/${managersEmployeeID}/${item.employeeID}`);

    }
    // This function fetches the PDF and opens it in print preview mode.
    function fetchAndPrint(employeeId) {
        fetch(`${backendUrl}/Employee/${employeeId}/generateFormLetter`)
            .then(response => response.blob())
            .then(blob => {
                // Convert blob to an Object URL
                const url = window.URL.createObjectURL(blob);
           
                // Open the PDF in a new window
                const pdfWindow = window.open(url);

                // Add an onload event to the new window. Trigger the print dialog when it's fully loaded.
                pdfWindow.onload = function () {
                    pdfWindow.print();
                };
            });
        
    }
    const handlePrintAll = () => {
        if (window.confirm("Are you sure you want to print letters for all employees?  This will generate a new code for each employee and the old codes will no longer work.")) {
            const params = new URLSearchParams();
            
            managerEmployees.forEach(employee => params.append('employeeIds', employee.employeeID));
            axios.get(`${backendUrl}/Employee/GenerateFormLettersForEmployees?${params.toString()}`, {
                responseType: 'blob'
            })
                .then(response => {
                    const blob = response.data;
                    const url = window.URL.createObjectURL(blob);
                    const pdfWindow = window.open(url);

                    localStorage.setItem('allowPrintAll', String(false));
                    setPrintAllClicked(true);
                    pdfWindow.onload = function () {
                        pdfWindow.print();
                    };
                })
                .catch(error => {
                    console.error("Error generating combined PDF:", error);
                });
        }
    };
    function printEmployee(item) {
        if (window.confirm('Are you sure you want to print the letter for ' +  item.firstName + ' ' + item.lastName + '? This will generate a new code for the employee and the old one will no longer work.')) {
            fetchAndPrint(item.employeeID);
             
        }
    }
    // Helper function to determine the background color based on PointsRemaining
    function getRowColor(pointsRemaining) {
        return pointsRemaining === 0 ? 'lightgreen' : 'lightyellow';
    }
    // Render the manager's employees data
    return (
        <div className="pageMarginMgr">
            {/* Navbar */}
            <Navbar bg="light" expand="lg" sticky="top">
                <Container>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link as={Link} to={`/ManagersView/${programYear}/${managersEmployeeID}`}>
                                Managers View
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to={`/ManagersView/${programYear}/${managersEmployeeID}`}
                                disabled={isFromManagerView}
                                style={{ pointerEvents: isFromManagerView ? 'none' : 'auto', color: isFromManagerView ? 'gray' : 'black' }}
                            >
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to={`/EmployeeSelection/${programYear}/${managersEmployeeID}`}
                                disabled={isEmployeeSelectionPage}
                                style={{ pointerEvents: isEmployeeSelectionPage ? 'none' : 'auto', color: isEmployeeSelectionPage ? 'gray' : 'black' }}
                            >
                                Employee View ({managerName})
                            </Nav.Link>
                            {/* <Nav.Link onClick={handlePrintAll} style={{marginLeft: '55px', backgroundColor: 'navy', color: 'white' }}>
                                Print All
                            </Nav.Link> */}
                            <Nav.Link
                                onClick={allowPrintAllBool ? handlePrintAll : (e) => e.preventDefault()}
                                style={{
                                    marginLeft: '55px',
                                    backgroundColor: allowPrintAllBool && printAllClicked==false ? 'navy' : 'lightgray', // Change the background color to indicate it's disabled
                                    color: allowPrintAllBool  && printAllClicked==false ? 'white' : 'darkgray', // Change the text color to indicate it's disabled
                                    pointerEvents: allowPrintAllBool && printAllClicked==false ? 'auto' : 'none' // Disable pointer events if not allowed
                                }}
                                disabled={!allowPrintAllBool  && printAllClicked} // This will apply the 'disabled' class from Bootstrap
                            >
                                Print All
                            </Nav.Link>
                            {/* Add more Nav.Link components as needed */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="header" style={{ position: 'sticky', top: '0' }}>
                <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
                    <div style={{ display: 'flex', alignItems: 'left' }}>
                        <img src={logo} alt="Company Logo" style={{ height: '110px', marginRight: '10px', marginTop: 25 }} />
                      
                    </div>
                    {managerName && (
                        <div className="manager-info" style={{ fontWeight: 'bold', marginTop: 25 }}>
                            <p style={{ margin: '5px 0' }}>Manager: {managerName}</p>
                        </div>
                    )}
                </header>
                {/* Divider */}
                <hr />
                {/* Display manager's employees data */}
                <div>
                    {managerEmployees.length > 0 ? (
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Employee ID</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Department</th>                                   
                                    <th>Max Points</th>
                                    <th>Points Remaining</th>
                                    <th>Order Complete</th>                                  
                                </tr>
                            </thead>
                            <tbody>
                                {managerEmployees.map((employee) => (
                                    <tr key={employee.employeeID} style={{ backgroundColor: getRowColor(employee.pointsRemaining) }}>
                                        <td>{employee.employeeID}</td>
                                        <td>{employee.firstName}</td>
                                        <td>{employee.lastName}</td>
                                        <td>{employee.department}</td>                                       
                                        <td style={ {textAlign: 'center' } }>{employee.maxPoints}</td>
                                        <td style={ {textAlign: 'center' } }>{employee.pointsRemaining}</td>                                            
                                        <td style={ {textAlign: 'center' } }>{employee.orderComplete ? 'Yes' : 'No'}</td>
 
                                        <td>
                                            <button onClick={() => loadEmployee(employee)}>
                                                <FaEdit />
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => printEmployee(employee)}>
                                                <FaPrint />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No employees found for this manager.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManagersView;
